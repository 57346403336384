













import {
  ref,
  defineComponent,
  useFetch,
  computed,
  useRoute,
} from '@nuxtjs/composition-api';
import { merge } from 'lodash-es';
import { getMetaInfo } from '~/helpers/getMetaInfo';
import ProductReviewDetail from '~/components/FortyTwo/review/ProductReviewDetail.vue';
import { useProduct } from '~/modules/catalog/product/composables/useProduct';
import { useReview } from '~/modules/review/composables/useReview';
import type { Product } from '~/modules/catalog/product/types';
import useFtReviewScore from '~/modules/fortytwo/product/composables/useFtReviewScore';

export default defineComponent({
  name: 'FortytwoProductReviewList',
  components: {
    ProductReviewDetail,
  },
  props: ["id"],
  head() { 
    return getMetaInfo(this.product);
  },
  setup(props) {
    const route = useRoute();
    const { query: { page, itemsPerPage } } = route.value;
    const product = ref<Product | null>(null);
    const { getProductDetails } = useProduct();
    // decode product sku
    const productSku = computed(() => props.id ? Buffer.from(props.id, 'base64').toString() : '' );
    const reviewScore = computed(() => product.value?.getreviewscore ?? {});

    const getBaseSearchQuery = () => ({
      filter: {
        sku: {
          eq: productSku.value,
        },
      },
    });

    const getReviewBaseSearchQuery = () => ({
      filter: {
        sku: {
          eq: productSku.value,
        },
      },
      currentPage: page ? Number.parseInt(page.toString(), 10) : 1,
      pageSize: itemsPerPage ? Number.parseInt(itemsPerPage.toString(), 10) : 10,
    });

    const fetchProductBaseData = async (searchQuery = getBaseSearchQuery()) => {
      const result = await getProductDetails({
        ...searchQuery,
      });
      product.value = merge({}, product.value, result?.items[0] as Product ?? null);
    };

    // get custom product review score
    const { getReviewScore } = useFtReviewScore();
    const fetchCustomReviewScore = async (product_uid) => {
      const { data } = await getReviewScore(product_uid);
      product.value = merge({}, product.value, data);
    };
    
    // get reviews
    const reviews = ref(null);
    const { search: searchReviews } = useReview();
    const fetchReviews = async (query = getReviewBaseSearchQuery()) => {
      const productReviews = await searchReviews(query);
      const baseReviews = Array.isArray(productReviews) ? productReviews[0] : productReviews;
      reviews.value = baseReviews;
    };
    const newQuery = getReviewBaseSearchQuery();
    fetchReviews(newQuery);

    useFetch(async () => {
      await fetchProductBaseData();
      if(product.value.uid) {
        await fetchCustomReviewScore(product.value.uid);
      }
    });

    return {
      product,
      reviews,
      reviewScore,
    };
  }
});
