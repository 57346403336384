








































import {
  ref,
  defineComponent,
  computed,
  useContext,
} from '@nuxtjs/composition-api';
import {
  SfModal,
  SfHeading,
  SfRadio,
  SfButton,
} from '@storefront-ui/vue';
import { useUiState } from '~/composables/useUiState';

export default defineComponent({
  name: 'FortyTwoAddressModal',
  components: {
    SfModal,
    SfHeading,
    SfRadio,
    SfButton,
  },
  props: {
    address_details: {
      type: Array,
    }
  },
  setup() {
    const { isAddressModalOpen, toggleAddressModal } = useUiState();
    const selectedAddress = ref("");

    const getLabel = (address) => {
      let addresslabel = '';

      if (address.building_name) {
        addresslabel = addresslabel + address.building_name + ', ';
      }

      if (address.building_number) {
        addresslabel = addresslabel + address.building_number + ' ';
      }

      if (address.street_name) {
        addresslabel = addresslabel + address.street_name;
      }

      return addresslabel;
    };

    return {
      isAddressModalOpen,
      toggleAddressModal,
      selectedAddress,
      getLabel,
    };
  },
});
