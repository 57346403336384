



























import { SfAddressPicker, SfDivider } from '@storefront-ui/vue';
import { computed, defineComponent } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import UserAddressDetails from '~/components/UserAddressDetails.vue';
import { TransformedCustomerAddress } from '~/modules/customer/composables/types';
import { Countries, useUiState } from '~/composables';
import { CheckoutAddressForm, getInitialCheckoutAddressForm } from '~/helpers/checkout/address';
import SkeletonLoader from "~/components/SkeletonLoader/index.vue";

export default defineComponent({
  name: 'UserBillingAddressesV2',
  components: {
    SfAddressPicker,
    SfDivider,
    UserAddressDetails,
    SkeletonLoader,
  },
  props: {
    currentAddressId: {
      type: Number,
      default: null,
    },
    value: {
      type: Boolean,
      required: true,
    },
    showBillingAddress: {
      type: Boolean,
      required: false,
    },
    customerAddresses: {
      type: Array as PropType<TransformedCustomerAddress[]>,
      required: true,
    },
    address: {
      type: Object as PropType<CheckoutAddressForm>,
      default: getInitialCheckoutAddressForm(),
    },
    countries: {
      type: Array as PropType<Countries[]>,
      default: () => [],

    },
  },
  emits: ['updateAddressFormAction'],
  setup(props, { emit }) {    
    const { toggleAddressListModal } = useUiState();
    const selectedCustomerAddress = computed(() => props.customerAddresses.filter((address) => address.id == props.currentAddressId)[0]);

    const addressWithCountryName = computed(() => {
      const result = {
        ...props.address,
        countryName: props.countries
        .find(({ id }) => id === props.address.country_code)
        ?.full_name_locale
        ?? props.address.country_code,
        default_billing: selectedCustomerAddress.value ? selectedCustomerAddress.value.default_billing : false,
        default_shipping: selectedCustomerAddress.value ? selectedCustomerAddress.value.default_shipping : false,
      }
      return result;
    });

    const addNewAddressClick = () => {
      toggleAddressListModal();
      emit('updateAddressFormAction', 'create new');
    };

    return {
      addressWithCountryName,
      addNewAddressClick,
    };
  },
});
