












































































































































































































































































































import {
  SfButton,
  SfSelect,
  SfCheckbox,
} from '@storefront-ui/vue';
import FtInput from "~/components/customSFUI/vue/src/components/atoms/FtInput/FtInput.vue";
import { required, min, oneOf } from 'vee-validate/dist/rules';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import {
  ref,
  watch,
  computed,
  defineComponent,
  onBeforeMount,
} from '@nuxtjs/composition-api';
import omitDeep from 'omit-deep';
import { PropType } from 'vue';
import { CountryCodeEnum, useCountrySearch } from '~/composables';
import type { Countries, Country, UseCountrySearchParams } from '~/composables';
import addressGetter from '~/modules/customer/getters/addressGetter';
import type { TransformedCustomerAddress, CustomerAddressForm } from '~/modules/customer/composables/types';
import pcQuery from '~/modules/fortytwo/sglocate/pcQuery';
import { useUiState } from "~/composables";
import AddressModal from '~/modules/fortytwo/checkout/components/AddressModal.vue';

extend('required', {
  ...required,
  message: 'This field is required',
});

extend('min', {
  ...min,
  message: 'The field should have at least {length} characters',
});

extend('oneOf', {
  ...oneOf,
  message: 'Invalid country',
});

export default defineComponent({
  name: 'AddressForm',

  components: {
    SfButton,
    SfSelect,
    SfCheckbox,
    FtInput,
    ValidationProvider,
    ValidationObserver,
    AddressModal,
  },

  props: {
    address: {
      type: Object as PropType<TransformedCustomerAddress>,
      default: () => ({
        apartment: '',
        city: '',
        country_code: '' as CountryCodeEnum,
        company: '',
        firstname: '',
        lastname: '',
        postcode: '',
        region: {
          region_code: '',
          region_id: 0,
        },
        custom_attributes: [{}],
        street: [],
        telephone: '',
        default_shipping: false,
        default_billing: false,
      }),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const { load: loadCountries, search: searchCountry } = useCountrySearch();

    const form = ref<TransformedCustomerAddress | null>(null);

    const postalcodeMaxLength = ref(50);
    const telMaxLength = ref(50);
    const altTelMaxLength = ref(50);
    const countries = ref<Countries[]>([]);
    const countriesList = computed(() => addressGetter.countriesList(countries.value));
    const country = ref<Country | null>(null);
    const regionInformation = computed(() => addressGetter.regionList(country.value));

    const updateCountry = async (params: UseCountrySearchParams) => {
      country.value = await searchCountry(params);
      // form.value.region = { region_id: null, region_code: '' };
      form.value.postcode = "";
      form.value.telephone = "";
      form.value.custom_attributes[getCustomAttribute('alt_telephone')].value  = "";
      fillUpAddress('','','','');
      postalcodeError.value = false;
      if (country.value?.two_letter_abbreviation === 'SG') {
        postalcodeMaxLength.value = 6;
        telMaxLength.value = 8;
        altTelMaxLength.value = 8;
        showMoreAddress.value = false;
      } else {
        postalcodeMaxLength.value = 50;
        telMaxLength.value = 50;
        altTelMaxLength.value = 50;
        showMoreAddress.value = true;
      }
    };

    const getPropsCustomAttribute = (attribute_code) => {
      if (props.address.custom_attributes != null) {
        return props.address.custom_attributes?.findIndex(
          (custom_attribute) => custom_attribute.attribute_code == attribute_code
        );
      } else {
        return -1;
      }
    };

    watch(() => props.address, () => {
      const custom_attributes = [];

      //custom attributes used for sglocate
      var custom_attribute_codes = [
        "unit_no",
        "building_name",
        "building_type",
        "alt_telephone",
      ];

      if (props.address.apartment == undefined) {
        props.address.apartment = "";
      }

      custom_attribute_codes.forEach((custom_attribute_code) => {
        //check if required custom attributes are present, insert it with empty value if does not exist
        if (getPropsCustomAttribute(custom_attribute_code) != -1) {
          var element = {
            attribute_code:
              props.address?.custom_attributes[
                getPropsCustomAttribute(custom_attribute_code)
              ].attribute_code,
            value:
              props.address?.custom_attributes[
                getPropsCustomAttribute(custom_attribute_code)
              ].value,
          };
        } else {
          var element = { attribute_code: custom_attribute_code, value: "" };
        }
        custom_attributes.push(element);
      });

      form.value = {
        apartment: props.address.apartment,
        city: props.address.city,
        country_code: props.address.country_code,
        company: props.address.company,
        firstname: props.address.firstname,
        lastname: props.address.lastname,
        postcode: props.address.postcode,
        region: {
          region_code: '',
          region_id: null,
          ...props.address.region,
        },
        street: props.address.street,
        telephone: props.address.telephone,
        default_shipping: props.address.default_shipping || false,
        default_billing: props.address.default_billing || false,
        custom_attributes: custom_attributes
      } as TransformedCustomerAddress;

      if (props.address.postcode.length > 0) {
        showMoreAddress.value = true;
      }
    }, { immediate: true });

    const submitForm = () => {
      const regionId = regionInformation.value.find((r) => r.abbreviation === form.value.region.region_code)?.id;
      form.value.region = regionId
        ? { region_id: regionId }
        : {
          ...form.value.region,
          region_code: '',
          region_id: null,
        };

      emit('submit', {
        form: omitDeep(form.value, ['__typename']),
        // TODO: Handle Error
        onError: () => {},
      });
    };

    onBeforeMount(async () => {
      countries.value = await loadCountries();
      if (props.address.country_code) {
        country.value = await searchCountry({ id: props.address.country_code });
      }
      if (props.address.postcode.length > 0) {
        showMoreAddress.value = true;
      }
      if (props.address.country_code == 'SG') {
        postalcodeMaxLength.value = 6;
        telMaxLength.value = 8;
        altTelMaxLength.value = 8;
        if(props.address.postcode.length == 6) {
          buildingNameReadonly.value = true;
          streetNameReadonly.value = true;
        }
      }
    });

    const getCustomAttribute = (attribute_code) => {
      return form.value.custom_attributes?.findIndex(
        (custom_attribute) => custom_attribute.attribute_code == attribute_code
      );
    };

    const { isAddressModalOpen, toggleAddressModal } = useUiState();
    const { queryAddressDetails, address_details } = pcQuery();
    const buildingNameReadonly = ref(false);
    const streetNameReadonly = ref(false);
    const unitNotRequired = ref(false);
    const postalcodeError = ref(false);
    const showMoreAddress = ref(false);

    const fillUpAddress = (building_number, street_name, building_name, building_desc) => {
      //check if there is building number. if so, prepend to street with spacing
      buildingNameReadonly.value = building_name ? true : false;
      streetNameReadonly.value = street_name ? true : false;
      const building_no = building_number ? building_number + ' ' : '' ;
      // form.value.street = building_no + street_name.toString();
      form.value.street = [];
      form.value.street.push(building_no + street_name.toString());
      form.value.custom_attributes[getCustomAttribute('building_name')].value = building_name;
      form.value.custom_attributes[getCustomAttribute('building_type')].value = building_desc;
      showMoreAddress.value = true;
    }

    const fetchPostalCode = async (pc) => {
      if (form.value.country_code == 'SG') {
        //if postal code is 6 digits, start sglocate process
        if (pc.length == 6) {
          // reset
          buildingNameReadonly.value = false;
          streetNameReadonly.value = false;
          address_details.value = {
            error: false,
            error_message: '',
            details: {
              building_code: '',
              building_desc: '',
              building_name: '',
              building_number: '',
              street_name: ''
            }
          };
          //execute GraphQl to get address details with postcode
          await queryAddressDetails(pc);
          if (!address_details.value.error_message && Array.isArray(address_details.value.details)) {
            postalcodeError.value = false;
            //check if there are multiple addresses for this postalcode
            if (Object.keys(address_details.value.details).length > 1) {
              await toggleAddressModal();
            } else {
              await fillUpAddress(
                address_details.value.details[0].building_number,
                address_details.value.details[0].street_name,
                address_details.value.details[0].building_name ?? '',
                address_details.value.details[0].building_desc ?? ''
              );
            }
          } else {
            postalcodeError.value = true;
            // reset the field to empty
            await fillUpAddress('','','','');
          }
        } else {
          // reset the field to empty
          await fillUpAddress('','','','');
          showMoreAddress.value = false;
          postalcodeError.value = false;
        }
      }
    }

    const updateAddress = async (selectedAddress) => {
      await fillUpAddress(
        selectedAddress.building_number,
        selectedAddress.street_name,
        selectedAddress.building_name,
        selectedAddress.building_desc
      );
      await toggleAddressModal();
    };

    return {
      form,
      submitForm,
      countriesList,
      regionInformation,
      updateCountry,
      getCustomAttribute,
      isAddressModalOpen,
      toggleAddressModal,
      address_details,
      updateAddress,
      buildingNameReadonly,
      streetNameReadonly,
      unitNotRequired,
      postalcodeError,
      showMoreAddress,
      fetchPostalCode,
      postalcodeMaxLength,
      telMaxLength,
      altTelMaxLength,
    };
  },
});
