var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"form",attrs:{"id":"shipping-details-form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitForm)}}},[_c('div',{staticClass:"form__element form__element--half"},[_c('span',{staticClass:"form__element--label"},[_vm._v(_vm._s(_vm.$t('First Name') + ' *'))]),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"firstname","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('FtInput',{attrs:{"name":"firstname","maxlength":"50","valid":!errors[0],"error-message":_vm.$t(errors[0]),"disabled":_vm.loading},model:{value:(_vm.form.firstname),callback:function ($$v) {_vm.$set(_vm.form, "firstname", $$v)},expression:"form.firstname"}})]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"form__element form__element--half form__element--half-even"},[_c('span',{staticClass:"form__element--label"},[_vm._v(_vm._s(_vm.$t('Last Name') + ' *'))]),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required|min:2","name":"lastname"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('FtInput',{attrs:{"name":"lastname","maxlength":"50","valid":!errors[0],"error-message":_vm.$t(errors[0]),"disabled":_vm.loading},model:{value:(_vm.form.lastname),callback:function ($$v) {_vm.$set(_vm.form, "lastname", $$v)},expression:"form.lastname"}})]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"form__element form__element--half form__select sf-select--underlined"},[_c('span',{staticClass:"form__element--label"},[_vm._v(_vm._s(_vm.$t('Country') + ' *'))]),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"country_code","rules":"required|min:2","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfSelect',{attrs:{"name":"country","valid":!errors[0],"error-message":_vm.$t(errors[0]),"disabled":_vm.loading},on:{"input":function($event){return _vm.updateCountry({ id: $event })}},model:{value:(_vm.form.country_code),callback:function ($$v) {_vm.$set(_vm.form, "country_code", $$v)},expression:"form.country_code"}},[_c('SfSelectOption',{attrs:{"value":''}}),_vm._v(" "),_vm._l((_vm.countriesList),function(countryOption){return _c('SfSelectOption',{key:countryOption.id,attrs:{"value":countryOption.abbreviation}},[_vm._v("\n            "+_vm._s(countryOption.label)+"\n          ")])})],2)]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"form__element form__element--half form__element--half-even"},[_c('span',{staticClass:"form__element--label"},[_vm._v(_vm._s(_vm.$t('Postal Code') + ' *'))]),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"postcode","rules":_vm.postalcodeMaxLength === 6 ? 'required|min:6': 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('FtInput',{attrs:{"name":"postcode","maxlength":_vm.postalcodeMaxLength,"valid":!errors[0],"error-message":_vm.$t(errors[0]),"disabled":_vm.loading,"inputmode":"numeric","pattern":"[0-9]*"},on:{"input":function($event){return _vm.fetchPostalCode($event)}},model:{value:(_vm.form.postcode),callback:function ($$v) {_vm.$set(_vm.form, "postcode", $$v)},expression:"form.postcode"}})]}}],null,true)})],1),_vm._v(" "),(_vm.postalcodeError)?_c('div',{staticClass:"postalcode-error-msg"},[_c('span',[_vm._v(_vm._s(_vm.$t('We have detected that you may have entered an invalid postal code.')))]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('Please ignore this message if you are sure it\'s accurate.')))])]):_vm._e(),_vm._v(" "),(_vm.showMoreAddress)?[_c('div',{staticClass:"form__element"},[_c('span',{staticClass:"form__element--label"},[_vm._v(_vm._s(_vm.$t('Company (optional)')))]),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"","name":"company"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('FtInput',{attrs:{"name":"company","maxlength":"50","valid":!errors[0],"error-message":_vm.$t(errors[0]),"disabled":_vm.loading},model:{value:(_vm.form.company),callback:function ($$v) {_vm.$set(_vm.form, "company", $$v)},expression:"form.company"}})]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"form__element"},[_c('span',{staticClass:"form__element--label"},[_vm._v(_vm._s(_vm.$t('Building Name')))]),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"","name":"building_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('FtInput',{attrs:{"name":"building_name","maxlength":"50","readonly":_vm.buildingNameReadonly,"valid":!errors[0],"error-message":_vm.$t(errors[0]),"disabled":_vm.loading},model:{value:(_vm.form.custom_attributes[_vm.getCustomAttribute('building_name')].value),callback:function ($$v) {_vm.$set(_vm.form.custom_attributes[_vm.getCustomAttribute('building_name')], "value", $$v)},expression:"form.custom_attributes[getCustomAttribute('building_name')].value"}})]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"form__element"},[_c('span',{staticClass:"form__element--label"},[_vm._v(_vm._s(_vm.$t('Street Address') + ' *'))]),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required","name":"street"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('FtInput',{attrs:{"name":"street","maxlength":"50","readonly":_vm.streetNameReadonly,"valid":!errors[0],"error-message":_vm.$t(errors[0]),"disabled":_vm.loading},model:{value:(_vm.form.street),callback:function ($$v) {_vm.$set(_vm.form, "street", $$v)},expression:"form.street"}})]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"form__element form__element--half"},[_c('span',{staticClass:"form__element--label"},[_vm._v(_vm._s(_vm.$t('Block / Lobby')))]),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"","name":"apartment"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('FtInput',{attrs:{"name":"apartment","maxlength":"50","valid":!errors[0],"error-message":_vm.$t(errors[0]),"disabled":_vm.loading},model:{value:(_vm.form.apartment),callback:function ($$v) {_vm.$set(_vm.form, "apartment", $$v)},expression:"form.apartment"}})]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"form__element form__element--half form__element--half-even"},[_c('span',{staticClass:"form__element--label"},[_vm._v(_vm._s(_vm.$t('Unit / Apt No.') + "" + (_vm.unitNotRequired ? '' : ' *')))]),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"unit_no","rules":("" + (_vm.unitNotRequired ? 'min:1' : 'required|min:1')),"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('FtInput',{attrs:{"name":"unit_no","maxlength":"10","valid":!errors[0],"error-message":_vm.$t(errors[0]),"disabled":_vm.loading},model:{value:(_vm.form.custom_attributes[_vm.getCustomAttribute('unit_no')].value),callback:function ($$v) {_vm.$set(_vm.form.custom_attributes[_vm.getCustomAttribute('unit_no')], "value", $$v)},expression:"form.custom_attributes[getCustomAttribute('unit_no')].value"}}),_vm._v(" "),_c('SfCheckbox',{staticClass:"form__checkbox",attrs:{"label":_vm.$t('Not applicable'),"name":"unitNotRequired"},model:{value:(_vm.unitNotRequired),callback:function ($$v) {_vm.unitNotRequired=$$v},expression:"unitNotRequired"}})]}}],null,true)})],1)]:_vm._e(),_vm._v(" "),_c('div',{staticClass:"form__element"},[_c('span',{staticClass:"form__element--label"},[_vm._v(_vm._s(_vm.$t('City') + ' *'))]),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"city","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('FtInput',{attrs:{"name":"city","maxlength":"50","valid":!errors[0],"error-message":_vm.$t(errors[0]),"disabled":_vm.loading},model:{value:(_vm.form.city),callback:function ($$v) {_vm.$set(_vm.form, "city", $$v)},expression:"form.city"}})]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"form__element form__element--half"},[_c('span',{staticClass:"form__element--label"},[_vm._v(_vm._s(_vm.$t('Telephone No.') + ' *'))]),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"telephone","rules":_vm.telMaxLength === 8 ? 'required|min:8': 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('FtInput',{attrs:{"name":"telephone","maxlength":_vm.telMaxLength,"valid":!errors[0],"error-message":_vm.$t(errors[0]),"disabled":_vm.loading,"inputmode":"numeric","pattern":"[0-9]*"},model:{value:(_vm.form.telephone),callback:function ($$v) {_vm.$set(_vm.form, "telephone", $$v)},expression:"form.telephone"}})]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"form__element form__element--half form__element--half-even"},[_c('span',{staticClass:"form__element--label"},[_vm._v(_vm._s(_vm.$t('Alternate Telephone No.')))]),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"alt_telephone","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('FtInput',{attrs:{"name":"alt_telephone","maxlength":_vm.altTelMaxLength,"valid":!errors[0],"error-message":_vm.$t(errors[0]),"disabled":_vm.loading,"inputmode":"numeric","pattern":"[0-9]*"},model:{value:(_vm.form.custom_attributes[_vm.getCustomAttribute('alt_telephone')].value),callback:function ($$v) {_vm.$set(_vm.form.custom_attributes[_vm.getCustomAttribute('alt_telephone')], "value", $$v)},expression:"form.custom_attributes[getCustomAttribute('alt_telephone')].value"}})]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"form__element form__element--half form__element--half-even",attrs:{"hidden":""}},[_c('span',{staticClass:"form__element--label"},[_vm._v("Building Type")]),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"building_type","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('FtInput',{attrs:{"name":"building_type","maxlength":"50","valid":!errors[0],"error-message":_vm.$t(errors[0]),"disabled":_vm.loading,"readonly":""},model:{value:(_vm.form.custom_attributes[_vm.getCustomAttribute('building_type')].value),callback:function ($$v) {_vm.$set(_vm.form.custom_attributes[_vm.getCustomAttribute('building_type')], "value", $$v)},expression:"form.custom_attributes[getCustomAttribute('building_type')].value"}})]}}],null,true)})],1),_vm._v(" "),_c('SfCheckbox',{staticClass:"form__element form__checkbox",attrs:{"name":"isDefaultShipping","label":_vm.$t('Set as default delivery address'),"disabled":_vm.loading},model:{value:(_vm.form.default_shipping),callback:function ($$v) {_vm.$set(_vm.form, "default_shipping", $$v)},expression:"form.default_shipping"}}),_vm._v(" "),_c('SfCheckbox',{staticClass:"form__element form__checkbox",attrs:{"name":"isDefaultBilling","label":_vm.$t('Set as default billing address'),"disabled":_vm.loading},model:{value:(_vm.form.default_billing),callback:function ($$v) {_vm.$set(_vm.form, "default_billing", $$v)},expression:"form.default_billing"}}),_vm._v(" "),_c('div',{staticClass:"form"},[_c('div',{staticClass:"form__action"},[_c('SfButton',{staticClass:"form__button",attrs:{"type":"submit","disabled":_vm.loading}},[_vm._t("submit-button-content")],2)],1)])],2),_vm._v(" "),(_vm.isAddressModalOpen)?_c('AddressModal',{attrs:{"address_details":_vm.address_details.details},on:{"close":_vm.toggleAddressModal,"updateAddress":_vm.updateAddress}}):_vm._e()]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }